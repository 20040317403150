import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"]

  connect () {
    const container = document.getElementById(this.buttonTarget.dataset.id)
    container.classList.add("hidden")
  }

  open(e) {
    e.preventDefault()

    const container = document.getElementById(e.target.closest("a").dataset.id)
    container.classList.remove("hidden")
  }
}
