import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"]

  connect() {
    const container = document.getElementById(this.inputTarget.dataset.id)
    this.toggleClass(this.inputTarget.checked, container)
  }

  toggle(e) {
    const checkbox = document.getElementById(e.target.id)
    const container = document.getElementById(e.target.dataset.id)

    this.toggleClass(checkbox.checked, container)
  }

  toggleClass(isChecked, container) {
    if (isChecked) {
      container.classList.remove("hidden")
    } else {
      container.classList.add("hidden")
    }
  }
}
