import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"]

  toggle(e) {
    e.preventDefault()

    const state = this.buttonTarget.dataset.state
    if (state == "opened") { // close now...
      this.buttonTarget.querySelector("#expand").classList.add("hidden")
      this.buttonTarget.querySelector("#collapse").classList.remove("hidden")
      document.getElementById("legend-body").classList.add("hidden")

      this.buttonTarget.dataset.state = "closed"
    } else { // open now...
      this.buttonTarget.querySelector("#expand").classList.remove("hidden")
      this.buttonTarget.querySelector("#collapse").classList.add("hidden")
      document.getElementById("legend-body").classList.remove("hidden")

      this.buttonTarget.dataset.state = "opened"
    }
  }
}
