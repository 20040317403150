import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link"]
  static values = { defaultState: String }

  connect() {
    this.state = this.defaultStateValue

    if(document.getElementById("is_expanded").value == "true") {
      this.openPanel()
    } else {
      this.closePanel()
    }
  }

  toggle(e) {
    e.preventDefault()

    if(this.state == "open") { // closing...
      this.closePanel()
    } else if (this.state == "closed") { // opening...
      this.openPanel()
    }
  }

  openPanel() {
    let openLink = this.linkTarget.querySelector('#open')
    let closeLink = this.linkTarget.querySelector('#close')

    document.getElementById(this.linkTarget.dataset.advancedFieldsContainer).classList.remove("hidden")
    document.getElementById("is_expanded").value = true

    openLink.classList.add("hidden")
    closeLink.classList.remove("hidden")

    this.state = "open"
  }

  closePanel() {
    let openLink = this.linkTarget.querySelector('#open')
    let closeLink = this.linkTarget.querySelector('#close')

    document.getElementById(this.linkTarget.dataset.advancedFieldsContainer).classList.add("hidden")
    document.getElementById("is_expanded").value = false

    openLink.classList.remove("hidden")
    closeLink.classList.add("hidden")

    this.state = "closed"
  }
}
