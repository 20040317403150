import { Controller } from "stimulus";
import mapboxgl from '!mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["map"]

  connect() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiZ21wLW5ldHdvcmsiLCJhIjoiY2xiMTFoNzJ1MDNqejN2cWZ2aW5obm9payJ9.s6fkPGsixnnzm5mDDAbzgA'

    const locale = this.mapTarget.dataset.locale
    const params = {
      container: this.mapTarget,
      style: 'mapbox://styles/gmp-network/clb1afr3v000u15o743z2dfs9',
      center: [this.mapTarget.dataset.longitude, this.mapTarget.dataset.latitude],
      zoom: 2,
      projection: 'globe'
    }

    const map = new mapboxgl.Map(params)
    map.addControl(new mapboxgl.NavigationControl())

    mapboxgl.setRTLTextPlugin('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js')
    map.addControl(new MapboxLanguage({ defaultLanguage: locale }))

    map.on('load', () => {
      map.addSource('countries', {
        type: 'geojson',
        data: this.mapTarget.dataset.url
      })

      map.addLayer({
        'id': 'countries-layer',
        'type': 'fill',
        'source': 'countries',
        'paint': {
          'fill-color': {
            type: 'identity',
            property: `fill_color_${locale}`,
          },
          'fill-outline-color': {
            type: 'identity',
            property: 'fill_outline_color',
          }
        }
      })
    })

    // At low zooms, complete a revolution every two minutes.
    const secondsPerRevolution = 120;
    // Above zoom level 5, do not rotate.
    const maxSpinZoom = 5;
    // Rotate at intermediate speeds between zoom levels 3 and 5.
    const slowSpinZoom = 3;

    let userInteracting = false;
    let spinEnabled = this.mapTarget.dataset.shouldRotateGlobe.toLowerCase() === "true"

    if (!spinEnabled && this.mapTarget.dataset.countryId) {
      openPanelForCountry(this.mapTarget.dataset.countryId)
    }

    function spinGlobe() {
      const zoom = map.getZoom()
      if (spinEnabled && !userInteracting && zoom < maxSpinZoom) {
        let distancePerSecond = 360 / secondsPerRevolution
        if (zoom > slowSpinZoom) {
          // Slow spinning at higher zooms
          const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom)
          distancePerSecond *= zoomDif
        }
        const center = map.getCenter()
        center.lng -= distancePerSecond

        // Smoothly animate the map over one second.
        // When this animation is complete, it calls a 'moveend' event.
        map.easeTo({ center, duration: 1000, easing: (n) => n })
      }
    }

    function openPanelForCountry(id) {
      Rails.ajax({
        type: "GET",
        dataType: "script",
        url: `/map/countries/${id}`,
        success: function(data) {
          const html = new XMLSerializer().serializeToString(data)
          document.getElementById("info-panel-content").innerHTML = html
          document.getElementById("info-panel").classList.remove("hidden")
        }
      })
    }

    // Pause spinning on interaction
    map.on('mousedown', () => {
      userInteracting = true
    })

    map.on('moveend', () => {
      spinGlobe()
    })

    map.on('mousemove', function (e) {
      if (map.getLayer('countries-layer')) {
        const features = map.queryRenderedFeatures(e.point, { layers: ['countries-layer'] });
        if (!features.length) {
          map.getCanvas().style.cursor = '';
	  return;
        }

        map.getCanvas().style.cursor = 'pointer';
      }
    })

    map.on('click', function (e) {
      const features = map.queryRenderedFeatures(e.point, { layers: ['countries-layer'] });
      if (!features.length) {
        return;
      }

      const feature = features[0];
      const countryId = feature.properties.country_id
      openPanelForCountry(countryId)
    })

    spinGlobe()
  }
}
