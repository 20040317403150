import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    var activeTabName = this.data.get("tab")

    this.setActiveOption(activeTabName)

    var select = document.getElementById("mobile-tabs")

    if (activeTabName == "activities") {
      activeTabName = "activity"
    }
    select.value = activeTabName.replace(/^\w/, (c) => c.toUpperCase())
  }

  change(e) {
    e.preventDefault()
    this.setActiveOption(e.target.value)
  }

  setActiveOption(activeTabName) {
    var tabContents = document.getElementById("tab-contents")
    var tabsDropdown = document.getElementById("mobile-tabs")

    for (let i = 0; i < tabContents.children.length; i++) {
      tabContents.children[i].classList.remove("hidden")

      if (tabContents.children[i].id === activeTabName.toLowerCase()) {
        continue;
      }

      tabContents.children[i].classList.add("hidden")
    }
  }
}
