import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["modal", "image"]

  open(event) {
    event.stopPropagation()

    const modalTarget = this.modalTarget

    this.imageTarget.src = this.modalTarget.dataset.url

    document.onkeydown = function(e) {
      e = e || window.event;
      if (e.keyCode == 27) {
        modalTarget.classList.add('hidden')
      }
    };

    modalTarget.classList.remove('hidden')
  }

  close(event) {
    event.stopPropagation()

    this.modalTarget.classList.add('hidden')
  }
}
