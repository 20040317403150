import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"]

  connect() {
    console.log('connected')
  }

  handleChange(e) {
    const pageId = e.target.getAttribute("data-page-id")

    const path = `/moderator/pages/${pageId}/sections/new?type=${e.target.value}&name=${e.target.name}`
    fetch(path, {
      method: "GET",
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        "Content-Type": "application/json",
        "Accept": "text/html"
      },
    })
      .then(response => response.text())
      .then(html => {
        const content = this.element.querySelector('.content')
        console.log(content)
        content.innerHTML = html;
      })
      .catch(error => console.error("Error:", error));
  }
}
