import { Controller } from "stimulus";
import ReadSmore from 'read-smore';

export default class extends Controller {
  static targets = ["text"]

  connect() {
    const options = {
      wordsCount: 60,
      blockClassName: 'clamp',
      moreText: this.textTarget.dataset.moreText,
      lessText: this.textTarget.dataset.lessText
    }

    ReadSmore([this.textTarget], options).init()

    const className = "clamp"
    const smoreLinkClassName = className + "__link-wrap"
    const smoreElement = document.getElementsByClassName(smoreLinkClassName)[0]

    if (smoreElement) {
      smoreElement.classList.add(
        "flex",
        "flex-col",
        "items-center",
        "-mt-16",
        "px-4",
        "py-2",
        "text-sm",
        "font-medium",
        "text-blue",
        "bg-transparent",
        "hover:ring-2",
        "hover:ring-gray"
      )
    }
  }
}
