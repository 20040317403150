import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"]

  initialize() {
    this.toggle = this.toggle.bind(this)
    this.toggleClass = this.toggleClass.bind(this)
    this.abortController = new AbortController()
  }

  connect() {
    const container = document.getElementById(this.inputTarget.dataset.id)

    document.querySelectorAll(`input[name='${this.inputTarget.name}']`).forEach((input) => {
      input.addEventListener('change', this.toggle, { signal: this.abortController.signal });
    })

    this.toggleClass(this.inputTarget.checked, container)
  }

  toggle(e) {
    const radio = document.getElementById(e.target.id)
    const container = document.getElementById(e.target.dataset.id)

    this.toggleClass(radio.value === 'true', container)
  }

  toggleClass(shouldBeVisible, container) {
    if (shouldBeVisible) {
      container.classList.remove("hidden")
    } else {
      container.classList.add("hidden")
    }
  }

  disconnect() {
    this.abortController.abort()
  }
}
