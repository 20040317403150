import { Controller } from "stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    this.selectTarget.style.display = "none"

    let config = {}
    if(this.selectTarget.dataset.tags) {
      config.persist = false
      config.createOnBlur = true
      config.create = true
    }

    if(this.selectTarget.dataset.single) {
      config.maxItems = 1
    }

    if(this.selectTarget.dataset.removable) {
      config.plugins = ['remove_button']
    }

    this.tomSelect = new TomSelect(this.selectTarget, config)
  }

  disconnect() {
    if(this.tomSelect) {
      this.tomSelect.destroy()
    }
  }
}
