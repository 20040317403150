import { Controller } from "stimulus";
import mapboxgl from '!mapbox-gl';

export default class extends Controller {
  static targets = ["map"]

  connect() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiZ21wLW5ldHdvcmsiLCJhIjoiY2xiMTFoNzJ1MDNqejN2cWZ2aW5obm9payJ9.s6fkPGsixnnzm5mDDAbzgA'

    document.getElementById(this.mapTarget.id).style.width = this.mapTarget.parentNode.parentElement.clientWidth + 'px'

    const locale = this.mapTarget.dataset.locale
    const params = {
      container: this.mapTarget,
      style: 'mapbox://styles/gmp-network/clb1afr3v000u15o743z2dfs9',
      center: [this.mapTarget.dataset.longitude, this.mapTarget.dataset.latitude],
      zoom: 4,
      projection: 'mercator',
      dragPan: false
    }

    const map = new mapboxgl.Map(params)
    map.scrollZoom.disable()
    map.doubleClickZoom.disable()

    const features = []
    map.on('load', () => {
      JSON.parse(this.mapTarget.dataset.coordinates).forEach((coordinate, index) => {
        // Add a data source containing GeoJSON data.
        const sourceName = `coordinate-${index}`
        const fillName = `fill-${index}`
        const outlineName = `outline-${index}`
        const feature = {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': coordinate
          }
        }
        features.push(feature)

        map.addSource(sourceName, {
          'type': 'geojson',
          'data': feature
        });
        
        // Add a new layer to visualize the polygon.
        map.addLayer({
          'id': fillName,
          'type': 'fill',
          'source': sourceName, // reference the data source
          'layout': {},
          'paint': {
          'fill-color': this.mapTarget.dataset.fillColor,
          'fill-opacity': 0.5
          }
        });

        // Add a black outline around the polygon.
        map.addLayer({
          'id': outlineName,
          'type': 'line',
          'source': sourceName,
          'layout': {},
          'paint': {
            'line-color': '#000',
            'line-width': 1
          }
        });
      });

      const bounds = calculateMultiplePolygonBounds(features);
      map.fitBounds(bounds, { padding: 20, duration: 0 });
    });

    function calculateMultiplePolygonBounds(polygons) {
      var bounds = new mapboxgl.LngLatBounds();
      polygons.forEach(function (polygon) {
          polygon.geometry.coordinates[0].forEach(function (coordinate) {
              bounds.extend(coordinate);
          });
      });
      return bounds;
    }
  }
}
