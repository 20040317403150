import { Controller } from "stimulus";

export default class extends Controller {
  handleClick(e) {
    e.preventDefault() // Prevent the parent form submission

    const order = e.currentTarget.dataset.sectionReordererOrderValue
    const url = e.currentTarget.dataset.sectionReordererUrlValue

    const data = {
      order: order,
    };

    // Fetch API to send a PATCH request
    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'), // Include CSRF token
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(data) // Convert the data to a JSON string
    }).then(response => {
      if (response.ok) {
        // Handle success (e.g., update the UI)
        location.reload()
      } else {
        // Handle errors
        console.error("Request failed")
      }
    }).catch(error => {
      console.error("Network error", error)
    })
  }
}

