import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link"]

  click(e) {
    e.preventDefault()

    let param = this.linkTarget.dataset.paramName
    let value = ""

    if(document.getElementById(this.linkTarget.dataset.id).multiple) {
      value = Array
        .from(document.querySelectorAll(`#${this.linkTarget.dataset.id} option:checked`))
	.map(el => el.value)
	.join(",")
    } else {
      value = document.getElementById(this.linkTarget.dataset.id).value
    }

    if (param && value) {
      window.location = this.linkTarget.dataset.path + "?" + param + "=" + value
    }
  }
}
