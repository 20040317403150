import { Controller } from "stimulus";

export default class extends Controller {
  click(e) {
    e.preventDefault()

    var tabsContainer = document.getElementById("tabs")
    var tabContents = document.getElementById("tab-contents")
    var tabTogglers = tabsContainer.querySelectorAll("a")
    var tabName = e.target.getAttribute("id").replace("_tab", "")

    for (let i = 0; i < tabContents.children.length; i++) {
      tabTogglers[i].classList.add("text-gray-500")
      tabTogglers[i].classList.remove("border-blue-light", "border-b-2", "text-blue-light")
      tabContents.children[i].classList.remove("hidden")

      if (tabContents.children[i].id === tabName) {
        continue;
      }

      tabContents.children[i].classList.add("hidden")
    }

    e.target.classList.remove("text-gray-500")
    e.target.classList.add("border-blue-light", "border-b-2", "text-blue-light")
  }
}
