import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["modal"]

  open(event) {
    event.stopPropagation()

    const modalTarget = this.modalTarget
    document.onkeydown = function(e) {
      e = e || window.event;
      if (e.keyCode == 27) {
        modalTarget.classList.add('hidden')
      }
    };

    modalTarget.classList.remove('hidden')
  }

  close(event) {
    event.stopPropagation()

    this.modalTarget.classList.add('hidden')
  }

  copy(event) {
    const urlElement = document.getElementById("url")
    const buttonElement = document.getElementById("button")
    const oldButtonContent = buttonElement.textContent
    const newButtonContent = buttonElement.dataset.modalSuccessText

    navigator.clipboard.writeText(urlElement.value).then(() => {
      buttonElement.textContent = newButtonContent

      setTimeout(() => {
        buttonElement.textContent = oldButtonContent
      }, 5000)
    }, () => {
      console.error('Failed to copy url')
    })
  }
}
