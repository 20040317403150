import { Controller } from "stimulus";
import videojs from "video.js";

export default class extends Controller {
  static targets = ["player"]

  connect() {
    videojs(this.playerTarget.id)
  }
}
