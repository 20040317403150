import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "submit"]

  connect() {
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    if (this.inputTarget.value.trim() === "") {
      this.submitTarget.disabled = true;
      this.submitTarget.classList.add("disabled-btn")
    } else {
      this.submitTarget.disabled = false;
      this.submitTarget.classList.remove("disabled-btn")
    }
  }

  handleInput() {
    this.toggleSubmitButton();
  }
}

